<template>
  <v-form>
    <v-row>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Registration Number"
          v-model="record.RegistrationNO"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Make"
          v-model="record.Make"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Model"
          v-model="record.Model"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Brand"
          v-model="record.Brand"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Year"
          v-model="record.Year"
          type="number"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Color"
          v-model="record.Color"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Capacity"
          v-model="record.Capacity"
          type="number"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-checkbox label="Active" v-model="record.Status"></v-checkbox>
      </v-col>

      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
    },
  },
  data: () => ({
    record: {},
    loader: false,
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },
  },
};
</script>